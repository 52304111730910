import { CosmosDarkColors } from '@ax/cosmos/utils/colors';
import i18n from '@/i18n';

/**
 * A structure that links a field to a label (translated) and a color
 */
export interface SeverityConfigItem {
  field: string;
  label: string;
  color: string;
}

/**
 * A special item used to signal a <v-spacer> in the rollup
 * Some what of a hack to include a spacer type in the config
 */
export type SeverityConfigSpacer = Partial<SeverityConfigItem> & {
  field: 'spacer';
};

/**
 * Represents the set of accepted types in the config
 */
export type PrepatchSeverityConfig = Array<
  SeverityConfigItem | SeverityConfigSpacer
>;

/**
 * Unknown is a special catch-all, it's not a severity, but it carries
 * meaning in the context of severities within the app.
 *
 * It will handle cases like null, 'none', and 'other' severities
 */
export const unknownSeverityItem: SeverityConfigItem = {
  field: 'unknown',
  label: i18n.t('general.values.severity.unknown'),
  color: CosmosDarkColors.CasperGray,
};

/**
 * A ready to use spacer config item
 */
export const spacerConfig: SeverityConfigSpacer = { field: 'spacer' };

/**
 * A reasonable default for the rollup config
 *
 * Plays double duty as a default for colors and translations which may
 * not be ideal, but as the actual config of the rollup can be passed as
 * a parameter, I don't consider it a big deal
 */
export const severityConfig: PrepatchSeverityConfig = [
  {
    field: 'needsAttention',
    label: i18n.t('reports.prepatch.needsAttention'),
    color: CosmosDarkColors.DataRed,
  },
  spacerConfig,
  {
    field: 'low',
    label: i18n.t('general.values.severity.low'),
    color: CosmosDarkColors.DataLime,
  },
  {
    field: 'medium',
    label: i18n.t('general.values.severity.medium'),
    color: CosmosDarkColors.DataYellow,
  },
  {
    field: 'high',
    label: i18n.t('general.values.severity.high'),
    color: CosmosDarkColors.DataRed,
  },
  {
    field: 'critical',
    label: i18n.t('general.values.severity.critical'),
    color: CosmosDarkColors.DataPink,
  },
];
