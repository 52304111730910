var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-table',{attrs:{"data-test-id":"prepatch-report-table","column-select":false,"headers":_vm.headerConfig,"item-key":"id","options":_vm.options,"items":_vm.patches,"custom-sort":_vm.severitySort,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.cve",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatCves(value))+" ")]}},{key:"item.severity",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"color":_vm.getSeverityConfig(value).color,"label":""}},[_vm._v(" "+_vm._s(_vm.getSeverityConfig(value).label)+" ")])]}},{key:"item.patchTime",fn:function(ref){
var value = ref.value;
return [(value)?[_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatTime(value)))])]:[_vm._v("-")]]}},{key:"item.createTime",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.calcDaysExposed(value)))]),_c('div',{staticClass:"first-seen text-xs text-no-wrap"},[_vm._m(0),_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")])]}}])})}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v(_vm._s(_vm.$t('reports.prepatch.table.firstSeen')))])}]

export { render, staticRenderFns }