import { PrepatchResponse } from '@/models/reports/prepatch';
import httpClient from '../http-client';

const PREPATCH_ENDPOINT = '/reports/prepatch';

/**
 * Gets the prepatch report for a given organization and, optionally, group

 * @param orgId - the organization to pull the report for
 * @param groupId - the device group to load devices from
 * @param limit - max number of patches to load
 * @param offset - offset to start loading patches from
 * @returns the prepatch report
 * */
export function getPrepatchReport(
  orgId: number,
  groupId: number | null = null,
  limit = 250,
  offset = 0,
) {
  const params = {
    internal: 1,
    o: orgId,
    ...(groupId && { groupId }),
    limit,
    offset,
  };

  return httpClient
    .get<PrepatchResponse>(PREPATCH_ENDPOINT, { params })
    .then(({ data: { prepatch } }) => prepatch);
}
